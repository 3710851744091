<template>
    <div class="app-container">
        <div class="filter-container">
            <el-button class="filter-item" type="primary" @click="$router.go(-1)">返回</el-button>
        </div>
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="ID" width="80" align="center">
                <template slot-scope="scope">
                    <!-- {{ scope.$index }} -->
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="用户id" width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.user_id }}
                </template>
            </el-table-column>

            <el-table-column label="类型" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.type === 1">系统充值</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.type === 2">系统回收</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.type === 3">食堂美食</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="改变前" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.before }}
                </template>
            </el-table-column>

            <el-table-column label="改变值" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.change }}
                </template>
            </el-table-column>

            <el-table-column label="改变后" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.after }}
                </template>
            </el-table-column>

            <el-table-column label="备注" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.remark }}
                </template>
            </el-table-column>

            <el-table-column label="创建时间" min-width="120" align="center">
                <template slot-scope="scope">
                    {{ scope.row.created_at }}
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    user_id: '',
                },
            };
        },
        created() {
            this.listQuery.user_id = this.$route.query.user_id
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {

            },
        },
        methods: {
            getList() {
                this.listLoading = true;
                request({
                    url: "/api/backend/wallet/liveWalletLog",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
